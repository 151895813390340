import React, { Suspense, useState, useMemo } from 'react'
import {
  Redirect,
  Route,
  Switch,
  //  HashRouter
} from 'react-router-dom'
import { CContainer, CFade } from '@coreui/react'

import PrivateRoute from '../components/hooks/PrivateRoute'
import { UserContext } from '../components/hooks/UserContext'
import IndexAdmin from '../components/BackOffice';

// routes config
import routes from '../routes'

import RegisterUser from 'src/components/BackOffice/users/Register'
import Users from 'src/components/BackOffice/users/Users'
import Members from 'src/components/BackOffice/members/Members'
import EditUser from 'src/components/BackOffice/users/EditUser'
import IndexTypeNews from 'src/components/BackOffice/typeNews'
import AddTypeNews from 'src/components/BackOffice/typeNews/AddTypeNews'
import infobar from 'src/components/BackOffice/infobar'
import Consults from 'src/components/BackOffice/consults'
import IndexCatNews from 'src/components/BackOffice/catNews'
import IndexNews from 'src/components/BackOffice/news'
import AddNews from 'src/components/BackOffice/news/AddNews'
import EditTypeNews from 'src/components/BackOffice/typeNews/EditTypeNews'
import News from 'src/components/BackOffice/news/News'
import AddCatNews from 'src/components/BackOffice/catNews/AddCatNews'
import EditCatNews from 'src/components/BackOffice/catNews/EditCatNews'
import EditNews from 'src/components/BackOffice/news/EditNews'
import IndexEquipement from 'src/components/BackOffice/settings/equipement/indexEquipement'
import AddEquipement from 'src/components/BackOffice/settings/equipement/addEquipement'
import EditEquipement from 'src/components/BackOffice/settings/equipement/editEquipement'
import DetailsEquipement from 'src/components/BackOffice/settings/equipement/detailEquipement'
import IndexEquipementRealEstate from 'src/components/BackOffice/settings/equipementsRealEstate/indexEquipementRealEstate'
import AddEquipementRealEstate from 'src/components/BackOffice/settings/equipementsRealEstate/addEquipementRealEstate'
import EditEquipementRealEstate from 'src/components/BackOffice/settings/equipementsRealEstate/editEquipementRealEstate'
import Animals from 'src/components/BackOffice/annonces/animals'
import AnimalDetails from 'src/components/BackOffice/annonces/animalDetails'
import Articles from 'src/components/BackOffice/annonces/articles'
import ArticleDetails from 'src/components/BackOffice/annonces/articleDetails'
import BonPlans from 'src/components/BackOffice/annonces/bonPlans'
import BonPlanDetails from 'src/components/BackOffice/annonces/bonPlanDetails'
import Services from 'src/components/BackOffice/annonces/services'
import ServiceDetails from 'src/components/BackOffice/annonces/serviceDetails'
import Vehicles from 'src/components/BackOffice/annonces/vehicles'
import VehicleDetails from 'src/components/BackOffice/annonces/vehicleDetails'
import RealEstates from 'src/components/BackOffice/annonces/realEstates'
import RealEstateDetails from 'src/components/BackOffice/annonces/realEstateDetails'
import Jobs from 'src/components/BackOffice/jobOffer/Jobs'
import JobDetails from 'src/components/BackOffice/jobOffer/jobDetails'
import Banners from 'src/components/BackOffice/settings/banners'


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const TheContent = () => {
  const [user, setUser] = useState(null);

  const value = useMemo(() => ({ user, setUser }), [user, setUser]);
  return (
    <main className="c-main">
      <CContainer fluid>
        {/**      <HashRouter basename={"/test-5436726732"} > 
 */}
        <Suspense fallback={loading}>
          <UserContext.Provider value={value}>
            <Switch>

              <PrivateRoute component={IndexAdmin} path="/5467854/index" exact roles={['ROLE_SUPER_ADMIN', 'ROLE_JOURNALIST', 'ROLE_EDITOR_IN_CHIEF', 'ROLE_MANAGER', 'ROLE_EXPERT']} />

              <PrivateRoute exact path="/5467854/news/" component={IndexNews} roles={['ROLE_SUPER_ADMIN', 'ROLE_JOURNALIST', 'ROLE_EDITOR_IN_CHIEF']} />
              <PrivateRoute exact path="/5467854/news/add" component={AddNews} roles={['ROLE_SUPER_ADMIN', 'ROLE_JOURNALIST', 'ROLE_EDITOR_IN_CHIEF']} />
              <PrivateRoute exact path="/5467854/news/detail/:id" component={News} roles={['ROLE_SUPER_ADMIN', 'ROLE_JOURNALIST', 'ROLE_EDITOR_IN_CHIEF']} />
              <PrivateRoute exact path="/5467854/news/edit/:id" component={EditNews} roles={['ROLE_SUPER_ADMIN', 'ROLE_JOURNALIST', 'ROLE_EDITOR_IN_CHIEF']} />

              <PrivateRoute exact path="/5467854/type-news" component={IndexTypeNews} roles={['ROLE_SUPER_ADMIN', 'ROLE_EDITOR_IN_CHIEF']} />
              <PrivateRoute exact path="/5467854/type-news/add" name="Add-type-news" component={AddTypeNews} roles={['ROLE_SUPER_ADMIN', 'ROLE_EDITOR_IN_CHIEF']} />
              <PrivateRoute exact path="/5467854/info-bar" name="Info Bar" component={infobar} roles={['ROLE_SUPER_ADMIN', 'ROLE_EDITOR_IN_CHIEF']} />
              <PrivateRoute exact path="/5467854/type-news/edit/:id" name="edit-type-news" component={EditTypeNews} roles={['ROLE_SUPER_ADMIN', 'ROLE_EDITOR_IN_CHIEF']} />
              <PrivateRoute exact path="/5467854/category-news/" component={IndexCatNews} roles={['ROLE_SUPER_ADMIN', 'ROLE_EDITOR_IN_CHIEF']} />
              <PrivateRoute exact path="/5467854/category-news/add" component={AddCatNews} roles={['ROLE_SUPER_ADMIN', 'ROLE_EDITOR_IN_CHIEF']} />
              <PrivateRoute exact path="/5467854/category-news/edit/:id" component={EditCatNews} roles={['ROLE_SUPER_ADMIN', 'ROLE_EDITOR_IN_CHIEF']} />

              <PrivateRoute exact path="/5467854/setting/equipement" component={IndexEquipement} roles={['ROLE_SUPER_ADMIN']} />
              <PrivateRoute exact path="/5467854/setting/add-equipement" component={AddEquipement} roles={['ROLE_SUPER_ADMIN']} />
              <PrivateRoute exact path="/5467854/equipement/show/:id" component={DetailsEquipement} roles={['ROLE_SUPER_ADMIN']} />
              <PrivateRoute exact path="/5467854/equipement/edit/:id" component={EditEquipement} roles={['ROLE_SUPER_ADMIN']} />
              <PrivateRoute exact path="/5467854/setting/equipement/immobiliers" component={IndexEquipementRealEstate} roles={['ROLE_SUPER_ADMIN']} />
              <PrivateRoute exact path="/5467854/setting/add-equipement/immobilier" component={AddEquipementRealEstate} roles={['ROLE_SUPER_ADMIN']} />
              <PrivateRoute exact path="/5467854/immobilier/setting/equipement/edit/:id" component={EditEquipementRealEstate} roles={['ROLE_SUPER_ADMIN']} />
              <PrivateRoute exact path="/5467854/register" component={RegisterUser} roles={['ROLE_SUPER_ADMIN']} />
              <PrivateRoute exact path="/5467854/users/edit/:id" component={EditUser} roles={['ROLE_SUPER_ADMIN']} />
              <PrivateRoute exact path="/5467854/users" component={Users} roles={['ROLE_SUPER_ADMIN']} />
              <PrivateRoute exact path="/5467854/members" component={Members} roles={['ROLE_SUPER_ADMIN']} />


              <PrivateRoute exact path="/5467854/banners" name="manage-banners" component={Banners} roles={['ROLE_SUPER_ADMIN', 'ROLE_MANAGER']} />
              <PrivateRoute exact path="/5467854/consults" name="manage-consults" component={Consults} roles={['ROLE_SUPER_ADMIN', 'ROLE_MANAGER', 'ROLE_EXPERT']} />
              <PrivateRoute exact path="/5467854/animals" name="manage-animals" component={Animals} roles={['ROLE_SUPER_ADMIN', 'ROLE_MANAGER']} />
              <PrivateRoute exact path="/5467854/animals/details/:id" component={AnimalDetails} roles={['ROLE_SUPER_ADMIN', 'ROLE_MANAGER']} />
              <PrivateRoute exact path="/5467854/articles" name="manage-animals" component={Articles} roles={['ROLE_SUPER_ADMIN', 'ROLE_MANAGER']} />
              <PrivateRoute exact path="/5467854/articles/details/:id" component={ArticleDetails} roles={['ROLE_SUPER_ADMIN', 'ROLE_MANAGER']} />
              <PrivateRoute exact path="/5467854/services" name="manage-animals" component={Services} roles={['ROLE_SUPER_ADMIN', 'ROLE_MANAGER']} />
              <PrivateRoute exact path="/5467854/services/details/:id" component={ServiceDetails} roles={['ROLE_SUPER_ADMIN', 'ROLE_MANAGER']} />
              <PrivateRoute exact path="/5467854/bonPlans" name="manage-animals" component={BonPlans} roles={['ROLE_SUPER_ADMIN', 'ROLE_MANAGER']} />
              <PrivateRoute exact path="/5467854/bonplans/details/:id" component={BonPlanDetails} roles={['ROLE_SUPER_ADMIN', 'ROLE_MANAGER']} />
              <PrivateRoute exact path="/5467854/realEstates" name="manage-animals" component={RealEstates} roles={['ROLE_SUPER_ADMIN', 'ROLE_MANAGER']} />
              <PrivateRoute exact path="/5467854/realEstates/details/:id" component={RealEstateDetails} roles={['ROLE_SUPER_ADMIN', 'ROLE_MANAGER']} />
              <PrivateRoute exact path="/5467854/vehicles" name="manage-animals" component={Vehicles} roles={['ROLE_SUPER_ADMIN', 'ROLE_MANAGER']} />
              <PrivateRoute exact path="/5467854/vehicles/details/:id" component={VehicleDetails} roles={['ROLE_SUPER_ADMIN', 'ROLE_MANAGER']} />
              <PrivateRoute exact path="/5467854/jobs" component={Jobs} roles={['ROLE_SUPER_ADMIN', 'ROLE_MANAGER']} />
              <PrivateRoute exact path="/5467854/jobs/details/:id" component={JobDetails} roles={['ROLE_SUPER_ADMIN', 'ROLE_MANAGER']} />


              {routes.map((route, idx) => {
                return route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => (
                      <CFade>
                        <route.component {...props} />
                      </CFade>
                    )} />
                )
              })}
              <Redirect from="/" to="/5467854/index" />
            </Switch>
          </UserContext.Provider>
        </Suspense>
        {/**  </HashRouter> */}
      </CContainer>
    </main>
  )
}

export default React.memo(TheContent)
