import React, { useState, useEffect, useContext } from "react";

import {
  CCol,
  CNav,
  CTabContent,
  CTabPane,
  CTabs,
} from "@coreui/react";
import { SizeBike } from "src/components/FrontOffice/vehicles/bikes/SizeBike";
import Breadcrumb from "../../Breadcrumb";
import SearchService from "./searchService";
import FavoriteContext from "../../FavoriteContext";
import { IMAGES_BASE_URL } from "src/config";

function SearchServices() {
  const [showHideBike, setShowHideBike] = useState(false);

  const [banner, setBanner] = useState([]);
  const { banners } = useContext(
    FavoriteContext
  );
  useEffect(() => {
    setBanner(banners.find((item) => item.position === "service"));
  }, [banners]);

  return (
    <>
      <div className="vehicle-search">
        <div className="container">
          <Breadcrumb />
          <div className="row">
            <CCol

              md="6"
              className="justify-content-md-center container-vehicle"
            >
              <CTabs>
                <div className="row ">
                  <CCol xs="12" md="12">
                    <CNav variant="tabs" className="vehicule-tabs">
                      {/**<CNavItem className="vehicule-navItem articleIcon" onClick={hideBikeSize}>
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/icon_car.svg"}
                            className="icon-vehicle"
                            alt="petShop"
                          />
                        </CNavLink>
                      </CNavItem> */}

                    </CNav>
                  </CCol>
                </div>

                <CTabContent fade={false} className="vehicule-tabContent filteringPage">
                  <CTabPane className="searchFormWrapper">
                    <SearchService />
                  </CTabPane>


                </CTabContent>
              </CTabs>
            </CCol>
            {banner &&
              <img
                src={IMAGES_BASE_URL + '/public/uploads/banners/' + banner.path}
                alt={banner.alt}
              />
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchServices;
