import React, { useState, useEffect, useContext } from "react";

import {
  CCol,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
} from "@coreui/react";
import SearchCar from "./SearchCar";
import SearchTruck from "../trucks/SearchTruck";
import { SizeBike } from "../bikes/SizeBike";
import SearchBike from "../bikes/SearchBike";
import SearchMoto from "../motos/SearchMoto";
//import CIcon from "@coreui/icons-react";
import Breadcrumb from "../../Breadcrumb";
import FavoriteContext from "../../FavoriteContext";
import { IMAGES_BASE_URL } from "src/config";

function SearchVehicle() {
  const [showHideBike, setShowHideBike] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [banner, setBanner] = useState([]);
  const { banners } = useContext(
    FavoriteContext
  );
  useEffect(() => {
    setBanner(banners.find((item) => item.position === "vehicle"));
  }, [banners]);

  const showBikeSize = () => {
    setShowHideBike(true);
  };
  const hideBikeSize = () => {
    setShowHideBike(false);
  }

  useEffect(() => { setActiveTab(1) }, []);

  return (
    <>
      <div className="vehicle-search">
        <div className="container">
          <Breadcrumb />
          <div className="row">
            <CCol

              md="6"
              className="justify-content-md-center container-vehicle"
            >
              <CTabs >
                <div className="row ">
                  <CCol xs="12" md="12">
                    <CNav variant="tabs" className="vehicule-tabs">
                      <CNavItem className="vehicule-navItem" onClick={() => { hideBikeSize(); setActiveTab(1) }}>
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/icon_car.svg"}
                            className="icon-vehicle"
                            alt="car"
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem className="vehicule-navItem" onClick={() => { hideBikeSize(); setActiveTab(2) }}>
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/icon_truck.svg"}
                            className="icon-vehicle"
                            alt="truck"
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem className="vehicule-navItem" onClick={() => { showBikeSize(); setActiveTab(3) }}>
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/icon_bicycle.svg"}
                            className="icon-vehicle"
                            alt="bicyle"
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem className="vehicule-navItem" onClick={() => { hideBikeSize(); setActiveTab(4) }}>
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/icon_motorbike.svg"}
                            className="icon-vehicle"
                            alt="motorbike"
                          />
                        </CNavLink>
                      </CNavItem>

                    </CNav>
                  </CCol>
                </div>

                <CTabContent fade={false} className="vehicule-tabContent filteringPage">
                  <CTabPane className="searchFormWrapper">
                    {
                      // activeTab == 1 && 
                      <SearchCar />
                    }
                  </CTabPane>
                  <CTabPane className="searchFormWrapper">
                    {
                      // activeTab == 2 && 
                      <SearchTruck />
                    }
                  </CTabPane>
                  <CTabPane className="searchFormWrapper">
                    {
                      //activeTab == 3 && 
                      <SearchBike />
                    }
                  </CTabPane>
                  <CTabPane className="searchFormWrapper">
                    {
                      //activeTab == 4 &&                     
                      <SearchMoto />
                    }
                  </CTabPane>
                </CTabContent>
              </CTabs>
            </CCol>

            <CCol md="6">
              {showHideBike && (

                <SizeBike />

              )}
              <div className="sideImgWrapper">
                {banner &&
                  <img
                    src={IMAGES_BASE_URL + '/public/uploads/banners/' + banner.path}
                    alt={banner.alt}
                  />
                }
              </div>
            </CCol>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchVehicle;
